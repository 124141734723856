<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(createDiscountCode)"
        method="post"
        id="check-form"
      >
        <v-container>
          <v-row class="pt-10 mt-10">
            <!-- course input  -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.course }}<span class="red--text">(*)</span>
              </div>
              <div class="ml-4">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-row align="center">
                    <v-autocomplete
                      class="autoCompleteBox"
                      v-model="courseInput"
                      :items="courses"
                      outlined
                      dense
                      chips
                      small-chips
                      multiple
                      item-text="title"
                      item-value="id"
                      return-object
                    >
                      <template v-slot:selection="data"></template>
                    </v-autocomplete>
                    <v-checkbox
                      v-model="isGetAllCourse"
                      :label="lang.title.all"
                      hide-details
                      class="shrink mr-5 mb-12 pl-6 pr-4 checkbox-course"
                    ></v-checkbox>
                  </v-row>
                  <span class="validate-error text-danger text-danger">
                    {{ errors[0] }}
                    <div>{{ courseErrorMessage }}</div>
                  </span>
                </ValidationProvider>
              </div>
            </v-col>
            <!-- course choosed by admin -->
            <v-col class="mx-auto pa-0  " cols="10">
              <v-container>
                <v-row>
                  <v-col
                    cols="5"
                    class="pa-0 pr-6 mb-5"
                    v-for="item in courseChoiced"
                    :key="item.message"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :elevation="hover ? 12 : 2"
                        :class="{ 'on-hover': hover }"
                        max-width="100%"
                        outlined
                        class="grey lighten-3"
                      >
                        <v-list-item>
                          <v-list-item-content class="pa-0">
                            <div class="overline">
                              <div
                                class="float-right"
                                v-on:click="removeCourse(item)"
                              >
                                X
                              </div>
                              <div class="mt-1">
                                {{ item.title }}
                              </div>
                              <div>
                                {{ formatNumber(item.price) }}
                              </div>
                              <router-link
                                class="text-decoration-underline blue--text"
                                :to="{
                                  path: '/course',
                                  query: {
                                    course_slug: item.slug,
                                    viewType: 1
                                  }
                                }"
                              >
                                link
                              </router-link>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <!-- number of times usable by the user -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.payment }}<span class="red--text mr-4">(*)</span>
              </div>
              <div class="ml-4 mt-5">
                <v-row align="center">
                  <v-select
                    :items="paymentMethod"
                    class="shrink money-box"
                    dense
                    item-text="label"
                    v-model="paymentType"
                    outlined
                    color="primary"
                  ></v-select>
                  <vuetify-money
                      v-model="paymentNumber"
                      v-bind:label="''"
                      v-bind:placeholder="'Nhập số tiền khuyến mại'"
                      v-bind:readonly="false"
                      v-bind:disabled="false"
                      v-bind:outlined="true"
                      v-bind:clearable="true"
                      v-bind:valueWhenIsEmpty="''"
                      v-bind:options="options"
                      class="money-box-input"
                  />
                  <div class="icon-hnr pa-2 money__icon-fix">
                    <v-icon>
                      mdi-cash
                    </v-icon>
                  </div>
                </v-row>
                <span class="validate-error text-danger text-danger">
                  {{ paymentNumberErrorMessage }}
                </span>
              </div>
            </v-col>
            <!-- promotion code -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.amountUsed }}<span class="red--text">(*)</span>
              </div>
              <div class="ml-4 mt-5" style="max-width: 600px">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-row align="center">
                    <v-text-field
                      class="hnr-input input-amount-used"
                      :value="numberUsedInput"
                      outlined
                      single-line
                      dense
                      v-model="numberUsedInput"
                    ></v-text-field>
                  </v-row>
                  <span class="validate-error text-danger text-danger">
                    {{ errors[0] }} {{ usedNumberErrorMessage }}
                  </span>
                </ValidationProvider>
              </div>
            </v-col>
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.code }}
              </div>
              <div class="ml-4 mt-4">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-row align="center">
                    <v-text-field
                      class="hnr-input input-hnr"
                      :value="codeInput"
                      outlined
                      single-line
                      dense
                      v-model="codeInput"
                      :label="lang.title.code"
                    ></v-text-field>
                    <div class="icon-hnr pa-2" v-on:click="generateCode">
                      <v-icon>
                        mdi-cached
                      </v-icon>
                    </div>
                  </v-row>
                  <span class="validate-error text-danger text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </v-col>
            <!-- starting date -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.startDate }}<span class="red--text">(*)</span>
              </div>
              <div class="ml-4 mt-4">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-row align="center" v-on:click="dateStartBox = true">
                    <v-text-field
                      class="hnr-input input-hnr"
                      :value="startDateInput"
                      outlined
                      single-line
                      dense
                      v-model="startDateInput"
                    ></v-text-field>
                    <div class="icon-hnr pa-2" v-on:click="dateStartBox = true">
                      <v-icon>
                        mdi-calendar
                      </v-icon>
                    </div>
                  </v-row>
                  <span class="validate-error text-danger text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </v-col>
            <!--ending date-->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.endDate }}<span class="red--text">(*)</span>
              </div>
              <div class="ml-4 mt-4">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-row align="center" v-on:click="dateEndBox = true">
                    <v-text-field
                      class="hnr-input input-hnr"
                      :value="endDateInput"
                      outlined
                      single-line
                      dense
                      v-model="endDateInput"
                    ></v-text-field>
                    <div class="icon-hnr pa-2" v-on:click="dateEndBox = true">
                      <v-icon>
                        mdi-calendar
                      </v-icon>
                    </div>
                  </v-row>
                  <span class="validate-error text-danger text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </v-col>
            <!-- describe the discount code information -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.describe }}
              </div>
              <div class="ml-4 mt-5">
                <v-row align="center">
                  <hnr-textarea
                      :text="describeInput"
                      v-model="describeInput"
                      :label="lang.label.describe"
                      class="input-textArea"
                  ></hnr-textarea>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12">
              <span class="ml-14 pl-13 text-left red--text"
                >(*) : Bắt buộc nhập</span
              >
            </v-col>
            <v-col class="mx-auto text-right" cols="8">
              <hnr-button
                type="submit"
                form="check-form"
                rounded
                width="200"
                text="Tạo khuyến mại"
                color="blue"
                white-text="true"
              ></hnr-button>
            </v-col>
          </v-row>
        </v-container>
        <v-dialog v-model="dateStartBox" max-width="1000px">
          <v-card>
            <v-container>
              <v-row>
                <v-col cols="5"></v-col>
                <v-col cols="5">
                  <span class="title schel text-h3">{{
                    lang.title.startDatePicker
                  }}</span>
                </v-col>
                <v-col cols="2"></v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <ValidationProvider
                        name="picker"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-date-picker v-model="pickerStart"></v-date-picker>
                        <span class="validate-error text-danger text-danger">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="4">
                      <v-time-picker
                        v-model="timerStart"
                        format="ampm"
                      ></v-time-picker>
                    </v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                </v-col>

                <v-col cols="8"></v-col>
                <v-col cols="4">
                  <v-btn large @click="dateStartBox = false" class="btn-cancel">
                    Cancel
                  </v-btn>
                  <v-btn
                    large
                    @click="createStartDate()"
                    class="btn-submit white--text"
                    style="background-color: black"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- end time picking box -->
        <v-dialog v-model="dateEndBox" max-width="1000px">
          <v-card>
            <v-container>
              <v-row>
                <v-col cols="5"></v-col>
                <v-col cols="5">
                  <span class="title schel text-h3">{{
                    lang.title.endDatePicker
                  }}</span>
                </v-col>
                <v-col cols="2"></v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <ValidationProvider
                        name="picker"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-date-picker v-model="pickerEnd"></v-date-picker>
                        <span class="validate-error text-danger text-danger">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="4">
                      <v-time-picker
                        v-model="timerEnd"
                        format="ampm"
                      ></v-time-picker>
                    </v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                </v-col>

                <v-col cols="8">
                  <span class="validate-error text-danger text-danger">
                    {{ dateErrorMessage }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <v-btn large @click="dateEndBox = false" class="btn-cancel">
                    Cancel
                  </v-btn>
                  <v-btn
                    large
                    @click="createEndDate()"
                    class="btn-submit white--text"
                    style="background-color: black"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-form>
    </ValidationObserver>
    <!-- start time picking box -->
  </div>
</template>

<script>
import HnrButton from "../../../components/elements/hnr-button";
import HnrTextarea from "../../../components/elements/hnr-textarea";
import {
  CREATE_DISCOUNT_CODE,
  GENERATE_CODE
} from "@/store/discountCodes.module";
import { GET_LIST_COURSE } from "@/store/courses.module";
import { mapGetters } from "vuex";

export default {
  components: {
    HnrTextarea,
    HnrButton
  },

  data: () => ({
    isGetAllCourse: false,
    pickerStart: new Date().toISOString().substr(0, 10),
    timerStart: "11:15",
    dateStartBox: false,
    pickerEnd: new Date().toISOString().substr(0, 10),
    timerEnd: "11:15",
    dateEndBox: false,
    errorMessagesPayment: "",
    amountInputError: "",
    lang: {
      title: {
        course: "Chọn khóa học",
        amountUsed: "Số lần sử dụng",
        code: "Mã khuyến mại",
        startDate: "Ngày bắt đầu",
        endDate: "Ngày kết thúc",
        describe: "Mô tả",
        money: "Phần trăm(%)",
        all: "Tất cả",
        startDatePicker: "Ngày bắt đầu",
        endDatePicker: "Ngày kết thúc",
        payment: "Cách thức giảm giá"
      },
      label: {
        course: "Chọn khóa học",
        amountUsed: "0",
        code: "Nhập mã khuyến mại",
        startDate: "2021/05/12 17:01",
        endDate: "2021/15/12 15:02",
        describe: "Nhập mô tả"
      }
    },
    paymentMethod: [
      {
        value: 1,
        label: "Tiền mặt(đ)"
      },
      {
        value: 0,
        label: "Phần trăm(%)"
      }
    ],
    courseSearchKey: "",
    courseInput: [],
    paymentType: 0,
    numberUsedInput: "",
    describeInput: "",
    startDateInput: "",
    endDateInput: "",
    boxListCourse: true,
    paymentNumber: "",
    courseChoiced: [],
    paymentNumberErrorMessage: "",
    dateErrorMessage: "",
    courseListChoice: [],
    price: "",
    courseErrorMessage: "",
    isRemoveCourse: false,
    usedNumberErrorMessage: "",
    inputMoney:{
      label: "",
      placeholder: "Nhập mệnh giá theo phần trăm hoặc theo VNĐ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
    },
    options: {
      locale: "pt-BR",
      prefix: "VNĐ$",
      suffix: "",
      length: 100,
      precision: 0,
    },
    numberUsedChecked: false,
  }),
  mounted() {
    window.localStorage.removeItem("course");
    this.$store.dispatch(GENERATE_CODE, "");
    this.$store.dispatch(GET_LIST_COURSE);
  },
  watch: {
    isGetAllCourse: function(val) {
      if (val) {
        this.$store.dispatch(GET_LIST_COURSE);
        this.courseInput = this.courses;
      } else {
        if (!this.isRemoveCourse) {
          while (this.courseInput.length > 0) {
            this.courseInput.pop();
          }
        }
      }
    },
    numberUsedInput: function(val) {
      if (isNaN(val)) {
        this.usedNumberErrorMessage = "Ô này không được là chữ cái";
      } else if (val < 0) {
        this.usedNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
      } else {
        this.usedNumberErrorMessage = "";
      }
    },
    numberUsedChecked: function (val) {
      if (val == true) {
        this.numberUsedInput = 999999999;
        console.log(this.numberUsedInput);
      }
    },
    courseInput: function(val) {
      localStorage.setItem("course", JSON.stringify(val));
      this.courseChoiced = val;
    },
    paymentType: function() {
      this.courseChoiced = this.courseInput;
      this.paymentNumber = "";
    },
    paymentNumber: function(val) {
      this.price = "";
      this.price = this.formatNumber(val);
      if (!val) {
        this.courseChoiced = JSON.parse(localStorage.getItem("course"));
        this.paymentNumberErrorMessage = "Ô này không được bỏ trống";
        this.courseErrorMessage = "";
      } else if (isNaN(val)) {
        this.paymentNumberErrorMessage = "Ô này không được là chữ cái";
      } else if (val < 0) {
        this.paymentNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
      } else if (this.paymentType === 0) {
        if (val > 100) {
          this.paymentNumberErrorMessage = "Không được vượt quá 100%";
        } else {
          this.paymentNumberErrorMessage = "";
          if (this.courseChoiced.length === 0) {
            this.courseErrorMessage = "Bắt buộc nhập khóa học";
            this.courseChoiced = JSON.parse(localStorage.getItem("course"));
          } else {
            this.courseChoiced = JSON.parse(localStorage.getItem("course"));
            if (val) {
              if (this.paymentType) {
                for (let i = 0; i < this.courseChoiced.length; i++) {
                  this.courseChoiced[i].price =
                    parseInt(this.courseChoiced[i].price) - parseInt(val);
                }
              } else {
                for (let i = 0; i < this.courseChoiced.length; i++) {
                  this.courseChoiced[i].price =
                    parseInt(this.courseChoiced[i].price) *
                    ((100 - parseInt(val)) / 100);
                }
              }
            } else {
              this.courseChoiced = JSON.parse(localStorage.getItem("course"));
            }
          }
        }
      } else {
        this.paymentNumberErrorMessage = "";
        if (this.courseChoiced.length === 0) {
          this.courseErrorMessage = "Bắt buộc nhập khóa học";
          this.courseChoiced = JSON.parse(localStorage.getItem("course"));
        } else {
          this.courseChoiced = JSON.parse(localStorage.getItem("course"));
          if (val) {
            if (this.paymentType) {
              for (let i = 0; i < this.courseChoiced.length; i++) {
                this.courseChoiced[i].price =
                  parseInt(this.courseChoiced[i].price) - parseInt(val);
              }
            } else {
              for (let i = 0; i < this.courseChoiced.length; i++) {
                this.courseChoiced[i].price =
                  parseInt(this.courseChoiced[i].price) *
                  ((100 - parseInt(val)) / 100);
              }
            }
          } else {
            this.courseChoiced = JSON.parse(localStorage.getItem("course"));
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      codeInput: "getCode",
      courses: "listCourse"
    })
  },
  methods: {
    createStartDate() {
      this.startDateInput = this.pickerStart + " " + this.timerStart;
      this.dateStartBox = false;
      this.dateEndBox = true;
    },
    createEndDate() {
      if (this.pickerEnd.split("-")[0] < this.pickerStart.split("-")[0]) {
        this.dateErrorMessage = "Năm không hợp lệ";
      } else if (
        this.pickerEnd.split("-")[0] > this.pickerStart.split("-")[0]
      ) {
        this.dateErrorMessage = " ";
        this.endDateInput = this.pickerEnd + " " + this.timerEnd;
        this.dateEndBox = false;
      } else {
        if (this.pickerEnd.split("-")[1] < this.pickerStart.split("-")[1]) {
          this.dateErrorMessage = "Tháng không hợp lệ";
        } else if (
          this.pickerEnd.split("-")[1] == this.pickerStart.split("-")[1]
        ) {
          if (this.pickerStart.split("-")[2] >= this.pickerEnd.split("-")[2]) {
            this.dateErrorMessage = "Ngày không hợp lệ";
          } else {
            this.dateErrorMessage = " ";
            this.endDateInput = this.pickerEnd + " " + this.timerEnd;
            this.dateEndBox = false;
          }
        } else {
          this.dateErrorMessage = " ";
          this.endDateInput = this.pickerEnd + " " + this.timerEnd;
          this.dateEndBox = false;
        }
      }
    },
    generateCode() {
      this.$store.dispatch(GENERATE_CODE, "");
    },
    async createDiscountCode() {
      if (!this.paymentNumber) {
        this.paymentNumberErrorMessage = "Ô này không được bỏ trống";
      } else if (this.paymentNumber < 0) {
        this.paymentNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
      } else if (this.paymentType == 0) {
        if (this.paymentNumber > 100) {
          this.paymentNumberErrorMessage = "không được vượt quá 100%";
        } else if (this.numberUsedInput < 0) {
          this.usedNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
        } else {
          let courseIdList = [];
          for (let i = 0; i < this.courseInput.length; i++) {
            courseIdList.push(this.courseInput[i].id);
          }
          if (this.paymentType) {
            this.paymentType = 1;
          } else {
            this.paymentType = 2;
          }
          let data = {
            type: this.paymentType,
            course_id: courseIdList,
            amount_money: this.paymentNumber,
            max_of_use: this.numberUsedInput,
            discount_code: this.codeInput,
            start_time: this.startDateInput,
            end_time: this.endDateInput,
            description: this.describeInput,
            number_of_used: 0
          };
          await this.$store.dispatch(CREATE_DISCOUNT_CODE, data);
          this.$router.push({
            path: "/promotion/list"
          });
        }
      } else {
        let courseIdList = [];
        for (let i = 0; i < this.courseInput.length; i++) {
          courseIdList.push(this.courseInput[i].id);
        }
        let data = {
          type: this.paymentType,
          course_id: courseIdList,
          amount_money: this.paymentNumber,
          max_of_use: this.numberUsedInput,
          discount_code: this.codeInput,
          start_time: this.startDateInput,
          end_time: this.endDateInput,
          description: this.describeInput,
          number_of_used: 0
        };
        await this.$store.dispatch(CREATE_DISCOUNT_CODE, data);
        this.$router.push({
          path: "/promotion/list"
        });
      }
    },
    removeCourse(item) {
      this.isGetAllCourse = false;
      this.isRemoveCourse = true;
      const index = this.courseInput.indexOf(item);
      this.courseInput.splice(index, 1);
      this.paymentNumber = "";
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }
};
</script>

<style lang="scss">
.autoCompleteBox {
  .v-input__control {
    .v-input__slot {
      width: 102%;
    }
  }
}

.checkbox-course {
  padding: 9px !important;
  position: relative;
  left: -102px;
  border: 1px solid #757575;
  background: #e0e0e0;
  border-bottom: 1px solid lightgray;

  .v-input__control {
    .v-input__slot {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.checkbox-amount-used {
  height: 45px;
  padding: 9px !important;
  position: relative;
  left: -10%;
  border: 1px solid #757575;
  background: #e0e0e0;
  /* border-bottom: 1px solid lightgray; */
  margin-top: 18px;

  .v-input__control {
    .v-input__slot {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.money-box {
  position: relative;
}

.input-money {
  .v-input__control {
    .v-input__slot {
      width: 86.5%;
    }
  }
}

.input-amount-used {
  .v-input__control {
    .v-input__slot {
      width: 107%;
    }
  }
}

.input-textArea {
  .v-input__control {
    .v-input__slot {
      width: 87.5%;
    }
  }
}

.input-hnr {
  .v-input__control {
    .v-input__slot {
      width: 91.5%;
    }
  }
}

.autoCompleteBox {
  .v-input__control {
    v-input__slot {
      width: 70%;
    }
  }
}
.money-box-input{
  width: 53%;
  .v-input__control{
    height: 66px;
    .v-input__slot{
      fieldset{
        height: 45px;
      }
    }
    .v-text-field__prefix{
      display: none;
    }
    input{
      padding-bottom: 26px;
    }
  }
}
.money__icon-fix{
  left: -43px !important;
}
.icon-hnr {
  height: 43px;
  position: relative;
  left: -12.5%;
  bottom: 17px;
  border: 1px solid #757575;
  background: #e0e0e0;
  /* border-bottom: 2px solid lightgray; */
  margin-bottom: -3px;
}
</style>
